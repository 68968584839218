import * as Yup from "yup";
import { ERROR_STATMENTS } from "../../../../../../constants";

export const OnboardVerifyEmailFormInitValue = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  captcha: false,
  otp: "",
};

export const OnboardVerifyEmailFormValidationSchema = (verifyOtp = false) =>
  Yup.object().shape({
    firstname: Yup.string()
      .required(ERROR_STATMENTS.INPUT_REQUIRE)
      .max(40, ERROR_STATMENTS.MAX_LIMIT_40_CHARACTER)
      .min(3, "Atleast 3 charectore require")
      .matches(/^[a-zA-Z0-9]+$/, ERROR_STATMENTS.SPACE_SPECIAL_CHARACTER_NOT_ALLOWED),

    lastname: Yup.string()
      .required(ERROR_STATMENTS.INPUT_REQUIRE)
      .max(40, ERROR_STATMENTS.MAX_LIMIT_40_CHARACTER)
      .min(3, "Atleast 3 charectore require")
      .matches(/^[a-zA-Z0-9]+$/, ERROR_STATMENTS.SPACE_SPECIAL_CHARACTER_NOT_ALLOWED),

    username: Yup.string()
      .max(20, ERROR_STATMENTS.MAX_LIMIT_20_CHARACTER)
      .min(6, ERROR_STATMENTS.MIN_SIX_CHARACTER_REQUIRE)
      .matches(/^[\w\d]/, ERROR_STATMENTS.ALPHA_NUMERIC_REQUIRED)
      .matches(/^[^@]+$/, ERROR_STATMENTS.AT_NOT_ALLOWED)
      .required(ERROR_STATMENTS.INPUT_REQUIRE),

    email: Yup.string()
      .required(ERROR_STATMENTS.INPUT_REQUIRE)
      .max(160, ERROR_STATMENTS.MAX_LIMIT_160_CHARACTER)
      .email(ERROR_STATMENTS.ENTER_VALID_EMAIL),

    captcha: Yup.boolean()
      .required(ERROR_STATMENTS.INPUT_REQUIRE)
      .isTrue(ERROR_STATMENTS.CAPTCHA_VARIFICATION_REQUIRED),

    otp: verifyOtp ? Yup.string().required(ERROR_STATMENTS.INPUT_REQUIRE) : Yup.string(),
  });
