import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  AdminHomePage,
  OnBoardPage,
  UserHomePage,
} from "./app/pages";
import { store } from "./app/redux";
import { AdminPrivateRoutesURLs, PrivateRoutesURLs, PrivateRoute, OnboardRegistrationRoutesURLs } from "./app/routes";

toast.configure();
function App() {

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/* @todo: needs to separate this admin in another file */}
          <PrivateRoute
            path={AdminPrivateRoutesURLs.home}
            component={AdminHomePage}
          />

          <PrivateRoute path={`${PrivateRoutesURLs.user}:page`}
            component={UserHomePage} />

          <Route
            path={[`${OnboardRegistrationRoutesURLs.onboard}:type`, `${OnboardRegistrationRoutesURLs.home}`]}
            component={OnBoardPage}
          />

        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
