const API_ENDPOINTS = {
  todo: "todos/1",
  login: "login",
  register: "register-user",
  register_contact_info: "contact-info",
  register_skills: "dashboard/add-skills",
  verify: "confirm-user",
  resendOTP: "resend-otp",
  get_country: "country",
  get_state: "state",
  get_skills: "all-skills",
  get_education: "education"
};

export default API_ENDPOINTS;
