import React from "react";
import {
  AdminFooterComponent,
  AdminHeaderComponent,
  AdminSideBar,
} from "../../components";

const withAdminLayout = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <>
          <div className="wrapper">
            <AdminHeaderComponent />
            <AdminSideBar />
            <WrappedComponent />
            <AdminFooterComponent />
          </div>
        </>
      );
    }
  }
  return HOC;
};

export default withAdminLayout;
