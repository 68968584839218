import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { localStorageItems } from "../../../config/localStorage.config";
import { LANGUAGE_CODE } from "../../../constants";
import { setLocalStorage } from "../../../services";

const AuthHeaderComponent = () => {
  const { i18n } = useTranslation();
  const { error } = useSelector(auth => auth)
  useEffect(() => {
    setLocalStorage(localStorageItems.ACCEPT_LANGUAGE, LANGUAGE_CODE.ENGLISH)
  }, []);

  return (
    <header>
      <div className="header-container">
        {error}
        <div className="oops-lang-dropdown">
          <select
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
              setLocalStorage(localStorageItems.ACCEPT_LANGUAGE, e.target.value)
            }}
          >
            <option value={LANGUAGE_CODE.ENGLISH}>English</option>
            <option value={LANGUAGE_CODE.FRANCE}>France</option>
          </select>
        </div>
      </div>
    </header>
  );
};

export default AuthHeaderComponent;
