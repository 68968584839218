import React from "react";
import { AuthFooterComponent, AuthHeaderComponent } from "../../components";
import "../../assets/css/remixicon.css"
import "../../assets/css/style.css"

const withAuthLayout = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <div className="main-body main-wrapper">
          <AuthHeaderComponent />
          <WrappedComponent />
          <AuthFooterComponent />
        </div>
      );
    }
  }
  return HOC;
};

export default withAuthLayout;
