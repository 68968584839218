import React from "react";
import { Link } from "react-router-dom";

const SocialMediaLinksView = () => {
  return <>
    <section data-id="links" data-title="Links" className="pt-page pt-page-contact  pt-page-current">
      <div className="section-inner custom-page-content">
        <div className="row">
          <div className="col-md-4">
            <div className="section-title-block second-style">
              <h2 className="section-title">Links</h2>
            </div>
            <div className="section-content">
              <div className="row">

                <div className="col-6 col-xs-12 col-md-12 col-lg-6">
                  <div className="fw-col-inner">
                    <div className="info-list-w-icon">
                      <Link to="https://www.linkedin.com/in/mayank-chauhan-32060b26/" target="_blank">
                        <div className="info-block-w-icon social-box">
                          <div className="ci-icon">
                            <i className="ri-linkedin-fill"></i>
                          </div>
                          <div className="ci-text linkedin-text">
                            <h4>LinkedIn</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-xs-12 col-md-12 col-lg-6">
                  <div className="fw-col-inner">
                    <div className="info-list-w-icon">
                      <Link to="https://medium.com/@mkc110891" target="_blank">
                        <div className="info-block-w-icon social-box">
                          <div className="ci-icon">
                            <i className="ri-medium-fill"></i>
                          </div>
                          <div className="ci-text medium-text">
                            <h4>Medium</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-xs-12 col-md-12 col-lg-6">
                  <div className="fw-col-inner">
                    <div className="info-list-w-icon">
                      <Link to="https://stackoverflow.com/users/5676619/mayank-chauhan?tab=profile" target="_blank">
                        <div className="info-block-w-icon social-box">
                          <div className="ci-icon">
                            <i className="ri-stack-overflow-line"></i>
                          </div>
                          <div className="ci-text stackoverflow-text">
                            <h4>Stackoverflow</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-xs-12 col-md-12 col-lg-6">
                  <div className="fw-col-inner">
                    <div className="info-list-w-icon">
                      <Link to="https://twitter.com/mkc110891" target="_blank">
                        <div className="info-block-w-icon social-box">
                          <div className="ci-icon">
                            <i className="ri-twitter-fill"></i>
                          </div>
                          <div className="ci-text twitter-text">
                            <h4>Twitter</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-xs-12 col-md-12 col-lg-6">
                  <div className="fw-col-inner">
                    <div className="info-list-w-icon">
                      <Link to="https://github.com/mkc110891" target="_blank">
                        <div className="info-block-w-icon social-box">
                          <div className="ci-icon">
                            <i className="ri-github-fill"></i>
                          </div>
                          <div className="ci-text github-text">
                            <h4>Github</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="section-title-block second-style">
              <h2 className="section-title">Achievements</h2>
            </div>
            <div className="section-content">
              <div className="section-achievements">
                <div className="achievements-card-block">
                  <div className="box-card">
                    <h3>Stood 3rd in entire school in HSC</h3>
                    <h4>March - 2008</h4>
                  </div>
                  <div className="box-card">
                    <h3>Participated in HackerRank competition</h3>
                    <h4>June - 2019</h4>
                  </div>
                  <div className="box-card">
                    <h3>Attended several AWS and Oracle Cloud seminars and workshops</h3>
                    <h4>June - 2019</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default SocialMediaLinksView;