import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from 'react-select';
import { toastMessage } from "../../../../../services";
import { toastMessageType } from "../../../../../constants";

const OnboardSkillsView = (props) => {

  const { t, onSubmit, onAddSkill, selectedSkills, skills_options, onRemoveSkill } = props;

  const [skill, setSkill] = useState();
  const [level, setLevel] = useState();
  let hasSkill = false;

  return (
    <div className="registerFormBlock">
      <h6>{t("skill")}</h6>
      <div className="row">
        <div className="col-12">
          <div className="skills-group">
            <div className="form-group">
              <label className="label">{t("skill")}</label>
              <Select
                id="skill"
                name="skill"
                className="appearance-auto"
                onChange={selectedOption => {
                  let event = { target: { name: "skill", value: selectedOption.value } }
                  setSkill(event.target.value)
                }}
                options={skills_options()}
              />
            </div>
            <div className="form-group">
              <label className="label">{t("level")}</label>
              <Select
                id="level"
                name="level"
                className="appearance-auto"
                onChange={selectedOption => {
                  let event = { target: { name: "level", value: selectedOption.value } }
                  setLevel(event.target.value)
                }}
                options={[
                  { value: 'beginer', label: 'Beginner' },
                  { value: 'intermediate', label: 'intermediate' },
                  { value: 'advanced', label: 'Advance' }
                ]}
              />
            </div>
            <div className="form-group">
              <div className="skill-add-btn-block">
                <button
                  id="add"
                  onClick={() => {
                    hasSkill = false;
                    if (skill && level) {
                      (selectedSkills.length !== 0) &&
                        (selectedSkills.map(selectedSkill => {
                          if (selectedSkill.skill_name === skill) {
                            toastMessage(toastMessageType.warning, "Already added", 101);
                            hasSkill = true
                          }
                        }))
                      if (hasSkill === false) {
                        onAddSkill({ skill_name: skill, skill_level: level });
                      }
                    }
                    else if (skill && !level) toastMessage(toastMessageType.warning, "add skill level", 102)
                    else if (!skill && level) toastMessage(toastMessageType.warning, "add skill", 103)
                    else toastMessage(toastMessageType.warning, "select skill and level", 104)
                  }}
                  className="w-100 btn btn-sm btn-outline-secondary"
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="badge-list-block" >
            {selectedSkills.map((selectedSkill, index) => {
              return (
                <span className="badge badge-default" name={selectedSkill?.skill_name} key={index}>
                  {selectedSkill?.skill_name} - {selectedSkill?.skill_level}
                  <span className="badge-close"
                   onClick={e => onRemoveSkill(e.target.parentNode.parentNode.attributes["name"].nodeValue)}
                  >
                    <i className="ri-close-line"></i>
                  </span>
                </span>
              );
            })}
          </div>
        </div>

        <div className="col-12 mt-2 d-flex justify-content-end align-items-center">
          <Button
            id="submit"
            type="submit"
            onClick={() => {
              (selectedSkills.length === 0) ?
                // setError("add atleast 1 skill") :
                toastMessage(toastMessageType.warning, "add atleast 1 skill", 105) :
                onSubmit()
            }}
            variant="dark"
            className="ml-2"
          >
            {t("save-and-next")}
          </Button>
        </div>
      </div>
    </div >
  );
};

OnboardSkillsView.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAddSkill: PropTypes.func.isRequired,
  skills_options: PropTypes.func.isRequired,
  onRemoveSkill: PropTypes.func.isRequired,
  selectedSkills: PropTypes.array.isRequired,
};

export default OnboardSkillsView;
