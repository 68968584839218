import React from "react";

const TravelAndVisaView = () => {
  return <>
    <section data-id="travel-and-visa" data-title="Travel and Visa" className="pt-page pt-page-extra pt-page-current">
      <div className="section-inner custom-page-content">
        <div className="section-title-block second-style">
          <h2 className="section-title">Travel and Visa</h2>
        </div>
        <div className="section-content">
          <div className="section-travel-and-visa">
            <div className="row">
              <div className="col-md-4">
                <div className="box-card">
                  <h3>U.S.A</h3>
                  <div className="certificat-date-link">
                    <div className="certificat-date">
                      Visa valid Date : <span>June 15, 2020</span>
                    </div>
                    <div className="certificat-date">
                      Visa Expiry Date : <span>July 16, 2021</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default TravelAndVisaView;