import React from "react";
import { Link } from "react-router-dom";


const Certifications = () => {
    return <>

        <div className="col-md-12 mt-30 certification-block">
            <div className="section-title-block second-style">
                <h2 className="section-title">Certifications</h2>
            </div>
            <div className="section-content">
                <div className="section-certifications">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="box-card certificat-box-card">
                                <h3><Link to="https://www.udemy.com/course/learn-kubernetes/" target="_blank">Kubernetes for the Absolute Beginners - Hands-on</Link></h3>
                                <p className="font-16">Udemy</p>
                                <div className="certificat-date-link">
                                    <div className="certificat-date">
                                        Date: <span>June 4, 2021</span>
                                    </div>
                                    <div className="certificat-link">
                                        <Link to="https://www.udemy.com/certificate/UC-e451fe84-37ba-4864-bdfa-eab3036d5453/" target="_blank">
                                            <i className="ri-award-fill"></i>
                                            <span>View</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box-card certificat-box-card">
                                <h3><Link to="https://www.udemy.com/course/spring-web-services-tutorial/" target="_blank">Master Java Web Services and RESTful API with Spring Boot</Link></h3>
                                <p className="font-16">Udemy</p>
                                <div className="certificat-date-link">
                                    <div className="certificat-date">
                                        Date: <span>Jan 24, 2021</span>
                                    </div>
                                    <div className="certificat-link">
                                        <Link to="https://www.udemy.com/certificate/UC-4a6b43be-f818-4884-a449-27456c743dbe/" target="_blank">
                                            <i className="ri-award-fill"></i>
                                            <span>View</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box-card certificat-box-card">
                                <h3><Link to="https://www.udemy.com/course/mockito3/" target="_blank">Mockito: Next-Level Java Unit Testing</Link></h3>
                                <p className="font-16">Udemy</p>
                                <div className="certificat-date-link">
                                    <div className="certificat-date">
                                        Date: <span>Jan 24, 2021</span>
                                    </div>
                                    <div className="certificat-link">
                                        <Link to="https://www.udemy.com/certificate/UC-3afed89a-dbf5-4d9f-9d5b-3b404366c550/" target="_blank">
                                            <i className="ri-award-fill"></i>
                                            <span>View</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box-card certificat-box-card">
                                <h3><Link to="https://www.udemy.com/course/junit5-for-beginners/" target="_blank">Practical Java Unit Testing with JUnit 5</Link></h3>
                                <p>Udemy</p>
                                <div className="certificat-date-link">
                                    <div className="certificat-date">
                                        Date: <span>Jan 24, 2021</span>
                                    </div>
                                    <div className="certificat-link">
                                        <Link to="https://www.udemy.com/certificate/UC-ada0fce1-5732-4cf7-8a83-629d676a9ef4/" target="_blank">
                                            <i className="ri-award-fill"></i>
                                            <span>View</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box-card certificat-box-card">
                                <h3><Link to="/" target="_blank">
                                    KUBERNETES Administrator</Link></h3>
                                <p>AWS</p>
                                <div className="certificat-date-link">
                                    <div className="certificat-date">
                                        Pursuing: <span>Aug, 2021</span>
                                    </div>
                                    <div className="certificat-link">
                                        <Link to="/" target="_blank">
                                            <i className="ri-award-fill"></i>
                                            <span>View</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Certifications;