import { API_ENDPOINTS, axiosInstance } from "../../config";

export const doRegistration = (data) => {
  return axiosInstance.post(API_ENDPOINTS.register, data);
};

export const doRegistrationContactInfo = (data) => {
  return axiosInstance.post(API_ENDPOINTS.register_contact_info, data);
};
export const doRegistrationSkills = (data) => {
  return axiosInstance.post(API_ENDPOINTS.register_skills, data);
};

export const doVerify = (data) => {
  return axiosInstance.post(API_ENDPOINTS.verify, data);
};

export const doResendOTP = (data) => {
  return axiosInstance.post(API_ENDPOINTS.resendOTP, data);
};

export const doGetCountry = () => {
  return axiosInstance.get(API_ENDPOINTS.get_country);
}
export const doGetState = (data) => {
  return axiosInstance.get(API_ENDPOINTS.get_state, { params: { countryid: data } });
}
export const doGetSkills = () => {
  return axiosInstance.get(API_ENDPOINTS.get_skills);
}