import { createSlice } from "@reduxjs/toolkit";
import { performGetCountry, performGetState } from "../index";

const initialState = {
    loading: false,
    error: null,
    country: null,
    state: null,
}

export const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        setLocation: (state, action) => {
            state = {
                ...state,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(performGetCountry.fulfilled, (state) => {
                state.loading = false;
                state.error = null
            })
            .addCase(performGetCountry.pending, (state) => {
                state.loading = true
            })
            .addCase(performGetCountry.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error
            })

        builder
            .addCase(performGetState.fulfilled, (state) => {
                state.loading = false;
                state.error = null
            })
            .addCase(performGetState.pending, (state) => {
                state.loading = true
            })
            .addCase(performGetState.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error
            })
    }
})

export const { setLocation } = locationSlice.actions;

export default locationSlice.reducer;