import React from "react";

const AuthFooterComponent = () => {
  return (
    <footer>
      <div className="copyRights">© 2021 Oops</div>
    </footer>
    );
};

export default AuthFooterComponent;
