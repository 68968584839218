import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import OnBoardView from './view'
import { withAuthLayout } from '../../../hoc'
import { getLocalStorage, setLocalStorage } from '../../../services'
import { localStorageItems } from '../../../config'
import { performGetCountry, performGetState, setToken } from '../../../redux'

const register = "register"
const signIn = "signIn"

const OnBoardPage = () => {

    const { t } = useTranslation();
    const { token } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();
    const parmas = useParams();
    const stepName = parmas?.type;

    const history = useHistory();

    useEffect(() => {
        dispatch(performGetCountry())
        dispatch(performGetState(101))
        if (getLocalStorage(localStorageItems.jwt_token)) {
            dispatch(setToken({ token: getLocalStorage(localStorageItems.jwt_token) }));
        }
    }, []);

    useEffect(() => {
        if (token) {
            setLocalStorage(localStorageItems.jwt_token, token)
        }
    }, [token]);

    return (
        <OnBoardView signIn={signIn} register={register} t={t} stepName={stepName} history={history} />
    )
}

export default withAuthLayout(OnBoardPage)
