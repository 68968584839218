/**
 * @author Prashant Chauhan
 */
import React from "react";
import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import { Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

import {
  LoginFormInitValue,
  LoginFormValidationSchema,
} from "./utils/form.utils";
import { OnboardRegistrationRoutesURLs } from "../../../routes/onboard.routes";
import { ONBOARD_CONSTANT } from "../../../constants";
import { ErrorMessage } from "../../../components";

const SignInView = (props) => {

  const { t, handleSubmit, loading, password, username } = props;

  return (
    <>
      <div className="registerFormBlock">
        <div>{loading && <h3>Loading...</h3>}</div>
        <Formik
          initialValues={LoginFormInitValue}
          validationSchema={LoginFormValidationSchema}
          onSubmit={values => handleSubmit(values)}
          validateOnChange
        >
          {({ values, errors, handleSubmit, setFieldValue, handleChange, touched }) => {
            return (
              <form onSubmit={handleSubmit} id="sign-in-form">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label">{`${t(username)} ${t("or")} ${t("email")}`}</label>
                      <Field
                        id={username}
                        name={username}
                        value={values.username}
                        onChange={(e) => {
                          if ((e.target.value).includes("@")) setFieldValue("isEmail", true)
                          else { (values.isEmail) && setFieldValue("isEmail", false) }
                          handleChange(e)
                        }}
                        className="form-control"
                      />
                      {touched.username && <ErrorMessage t={t} error={(errors.username)} />}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label">{t(password)}</label>
                      <Field
                        value={values.password}
                        id={password}
                        name={password}
                        type={password}
                        className="form-control"
                      />
                      {touched.password && <ErrorMessage t={t} error={errors.password} />}
                    </div>
                    <div className="signInLinkAction-block">
                      <Link className="link-text" to={OnboardRegistrationRoutesURLs.forgotPassword}> {t(ONBOARD_CONSTANT.FORGOT_PASSWORD)} </Link>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group captcha-block">
                      <ReCAPTCHA
                        // eslint-disable-next-line
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                        onChange={(e) => {
                          if (e) setFieldValue("captcha", true)
                          else if (!e) setFieldValue("captcha", false)
                        }}
                      />
                      {touched.captcha && <ErrorMessage t={t} error={errors.captcha} />}
                    </div>
                  </div>
                  <div className="col-12 mt-2 d-flex justify-content-end align-items-center">
                    <Button
                      id="submit"
                      variant="dark"
                      type="submit"
                      className="ml-2"
                      onClick={handleSubmit}
                    >
                      {t(ONBOARD_CONSTANT.SUBMIT)}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

SignInView.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  todo: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default SignInView;
