import React from "react";
import PropTypes from "prop-types";
import { Field, Formik } from "formik";
import {
  OnboardVerifyEmailFormInitValue,
  OnboardVerifyEmailFormValidationSchema,
} from "./utils/form.utils";
import { useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { ONBOARD_CONSTANT } from "../../../../../constants";
import ErrorMessage from "../../../../../components/common/ErrorMessage";

const OnboardVerifyEmailView = ({
  t,
  onSubmit,
  verifyOtp,
  onVerifyOtp,
  onResendOtp,
  editInfo
}) => {
  const { onboardData } = useSelector(({ registration }) => registration);

  return (
    <div className="registerFormBlock">
      <h6>{t("verify-email")}</h6>
      <Formik
        initialValues={{ ...OnboardVerifyEmailFormInitValue, ...onboardData }}
        onSubmit={(values, { setSubmitting }) => {
          verifyOtp
            ? onVerifyOtp(values, setSubmitting)
            : onSubmit(values, setSubmitting);
        }}
        validationSchema={OnboardVerifyEmailFormValidationSchema(verifyOtp)}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit} id="verify-email-form">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("firstname")}</label>
                    <Field
                      id="firstname"
                      type="text"
                      name="firstname"
                      className="form-control"
                      readOnly={verifyOtp}
                    />
                    {touched.firstname && <ErrorMessage id={"firstname-error"} t={t} error={errors.firstname} />}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("lastname")}</label>
                    <Field
                      id="lastname"
                      type="text"
                      name="lastname"
                      className="form-control"
                      readOnly={verifyOtp}
                    />
                    {touched.lastname && <ErrorMessage id={"lastname-error"} t={t} error={errors.lastname} />}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("username")}</label>
                    <Field
                      id="username"
                      type="text"
                      name="username"
                      className="form-control"
                      readOnly={verifyOtp}
                    />
                    {touched.username && <ErrorMessage id={"username-error"} t={t} error={errors.username} />}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("email")}</label>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                      readOnly={verifyOtp}
                    />
                    {touched.email && <ErrorMessage id={"email-error"} t={t} error={errors.email} />}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group captcha-block">
                    {!verifyOtp ? (<>
                      <ReCAPTCHA
                        // eslint-disable-next-line
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                        onChange={(e) => {
                          if (e) setFieldValue("captcha", true)
                          else if (!e) setFieldValue("captcha", false)
                        }}
                      />
                      {<ErrorMessage id={"email-error"} t={t} error={errors.captcha} />}
                    </>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  {verifyOtp ? (
                    <div className="form-group">
                      <label className="label">{t("otp")}</label>
                      <Field
                        id="otp"
                        type="otp"
                        name="otp"
                        className="form-control"
                      />
                      <p
                        id="resend-otp"
                        role="button"
                        onClick={() => onResendOtp({ email: values?.email })}
                        className="helper-text text-info"
                      >
                        {t(ONBOARD_CONSTANT.RESEND_OTP)}
                      </p>
                      <ErrorMessage id={"email-error"} t={t} error={errors.otp} />
                    </div>
                  ) : null}
                </div>
                <div className="col-12 mt-2 d-flex justify-content-end align-items-center">
                  {verifyOtp &&
                    <Button type="button" variant="light" onClick={editInfo}>
                      {t("Edit information")}
                    </Button>
                  }
                  <Button id="submit" variant="dark" type="submit" className="ml-2">
                    {t(verifyOtp ? "save-and-next" : "verify-email")}
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

OnboardVerifyEmailView.propTypes = {
  t: PropTypes.func.isRequired,
  verifyOtp: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onVerifyOtp: PropTypes.func.isRequired,
  onResendOtp: PropTypes.func.isRequired,
  editInfo: PropTypes.func.isRequired,
};

export default OnboardVerifyEmailView;
