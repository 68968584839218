/*eslint-disable*/
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { PrivateRoutesURLs, AdminPrivateRoutesURLs, OnboardRegistrationRoutesURLs } from "../index";
import { clearLocalStorage } from "../../services";

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { loading, token, user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    console.log("authuser")
  }, []);

  const isValidUser = () => {
    // check for access rigths as well.
    return (
      token && // if store contain token for now so user is valid
      // user?.id && // will required after
      (Object.values(PrivateRoutesURLs).some((route) =>
        route.includes(rest.location.pathname)
      ) ||
        Object.values(AdminPrivateRoutesURLs).some((route) =>
          route.includes(rest.path)
        ))
    );
  };

  const notValidUser = () => {
    clearLocalStorage();
    return <Redirect to={OnboardRegistrationRoutesURLs.home} />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <>Loading...</>
        ) : isValidUser() ? (
          <Component {...props} />
        ) : (
          notValidUser()
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
