/**
 * @author Prashant Chauhan
 */
import * as Yup from "yup";
import { ERROR_STATMENTS } from "../../../../constants";

export const LoginFormInitValue = {
  username: "",
  password: "",
  isEmail: false,
  captcha: false
};

export const LoginFormValidationSchema = Yup.object().shape({
  username: Yup.string().when("isEmail", {
    is: false,
    then: Yup.string()
      .min(6, ERROR_STATMENTS.MIN_SIX_CHARACTER_REQUIRE)
      .max(20, ERROR_STATMENTS.MAX_LIMIT_20_CHARACTER)
      .matches(/^[\w\d]/, ERROR_STATMENTS.ALPHA_NUMERIC_REQUIRED)
      .required(ERROR_STATMENTS.INPUT_REQUIRE),
    otherwise: Yup.string()
      .max(160, ERROR_STATMENTS.MAX_LIMIT_160_CHARACTER)
      .email(ERROR_STATMENTS.ENTER_VALID_EMAIL)
      .required(ERROR_STATMENTS.INPUT_REQUIRE),
  }),

  isEmail: Yup.boolean(),

  captcha: Yup.boolean()
    .required(ERROR_STATMENTS.INPUT_REQUIRE)
    .isTrue(ERROR_STATMENTS.CAPTCHA_VARIFICATION_REQUIRED),

  password: Yup.string()
    .min(8, ERROR_STATMENTS.MIN_EIGHT_CHARACTER_REQUIRE)
    .max(15, ERROR_STATMENTS.MAX_LIMIT_FIFTEEN)
    .matches(/[A-Z]/, ERROR_STATMENTS.CAPITAL_LETTER_REQUIRE)
    .matches(/[0-9]/, ERROR_STATMENTS.NUMBER_REQUIRED)
    .matches(/[!@#$%^&*]/, ERROR_STATMENTS.SPECIAL_CHARACTER_REQUIRED)
    .required(ERROR_STATMENTS.INPUT_REQUIRE)
});