import React from "react";
import { Link } from "react-router-dom";
import References from "./References";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ExperienceInformationView = () => {
  return <>
    <section data-id="experience" data-title="Experience" className="pt-page pt-page-resume pt-page-current">
      <div className="section-inner custom-page-content">
        <div className="row">
          <div className="col-md-8">
            <div className="section-title-block second-style">
              <h2 className="section-title">Experience</h2>
            </div>
            <div className="section-content">
              <div className="experience-timeline timeline timeline-second-style clearfix">
                <div className="timeline-item clearfix">
                  <div className="left-part">
                    <div className="sticky-block">
                      <h5 className="item-period">Apr – 2020 | Present</h5>
                      <h5 className="company-title">GlobalVox Ventures Pvt Ltd</h5>
                      <h5 className="company-location-title">A&apos;bad, Gujarat - India</h5>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="right-part">
                    <div className="experience-accordion-block">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="panel-title">
                              <div className="project-title-block">
                                Project: <span className="project-title">EaseVisitor</span>
                                <span className="pipe"></span>
                                <span className="position-title">Tech Lead</span>
                              </div>
                              <div className="project-stack-block">
                                <div className="project-info-tag-block">
                                  <span className="project-info-tag">Java Thymeleaf</span>
                                  <span className="project-info-tag">Java Spring</span>
                                  <span className="project-info-tag">Angular TyoeScript</span>
                                  <span className="project-info-tag">MySQL</span>
                                  <span className="project-info-tag">Gradle</span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="text project-info-block">
                              <div className="project-info-card">
                                <h5>Project Brief</h5>
                                <p>
                                  EaseVisitor is a visitor management system based on SAAS model. <Link to="https://www.easevisitor.com/" target="_blank"> www.easevisitor.com</Link>. It automates the entire process of managing visitors in a facility. It has a web application + a kiosk application + also integrates with access control hardware (Facial recognition based). The application is currently being offered both as SAAS and on-premise solution.
                                </p>
                                <h5>Responsibilities</h5>
                                <ul className="project-info-list">
                                  <li>Managing team of 8 developers, reporting to PM, discussions with BA and helping in scoping</li>
                                  <li>Code review and code merging</li>
                                  <li>Devops – docker, kubernetes and managing multiple instances of the application (Development, Test, UAT, Demo, Production)</li>
                                  <li>Automation script using selenium</li>
                                  <li>Used JUnit to create unit test cases</li>
                                  <li>Created Angular architecture using angular10</li>
                                  <li>Performance improvement using jMeter tool and jConsole. Performance improved using proper indexing in the database, implementing Apache load balancer, removed unnecessary eager from the entities and more</li>
                                  <li>Was involved with hands on coding on JAVA modules and Angular as required by the team </li>
                                </ul>
                                <h5>Integrations done</h5>
                                <ul className="project-info-list">
                                  <li>Google place API’s for address/location. </li>
                                  <li>Microblink integration for pulling data from ID cards </li>
                                  <li>Facial Recognization Device for thrmal scanning and attendance</li>
                                  <li>Watch List Screening API and Sex Offender List API</li>
                                </ul>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <div className="panel-title">
                              <div className="project-title-block">
                                Project: <span className="project-title">Visitor Management System</span>
                                <span className="pipe"></span>
                                <span className="position-title">Tech Lead</span>
                              </div>
                              <div className="project-stack-block">
                                <div className="project-info-tag-block">
                                  <span className="project-info-tag">Spring boot</span>
                                  <span className="project-info-tag">Microservices</span>
                                  <span className="project-info-tag">JPA</span>
                                  <span className="project-info-tag">Hibernate</span>
                                  <span className="project-info-tag">Eureka</span>
                                  <span className="project-info-tag">RabbitMQ</span>
                                  <span className="project-info-tag">Angular</span>
                                  <span className="project-info-tag">MSSQL</span>
                                  <span className="project-info-tag">Oracle 19c</span>
                                  <span className="project-info-tag">Redis cache</span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="panel-body">
                              <div className="project-info-card">
                                <h5>Project Brief</h5>
                                <p>
                                  The project was to map columns of a database table – in Oracle EBS. The utility duplicated certain tables and then provided an interface to be able to add and remove columns from the mapped duplicated tables. This was used to create dynamic views of the table and then work on them without affecting the original data tables in EBS. The tool could then generate XML based data files.
                                </p>
                                <h5>Responsibilities</h5>
                                <ul className="project-info-list">
                                  <li>Designed the Architecture for development </li>
                                  <li>Scoping, guiding developers, code reviews</li>
                                  <li>DevOps -  Jenkins, Docker and Kubernetes</li>
                                  <li>Release management</li>
                                  <li>Performance tuning of the application using Apache load balancer and tuned the application to work with 500 concurrent users performing different scenarios.</li>
                                  <li>Security testing clearance of the application tested via BurpSuite Pro</li>
                                  <li>Angular development using workspace architecture</li>
                                  <li>Used concepts of shared library, shared modules and RxJS</li>
                                  <li>Created custom libraries in Angular </li>
                                  <li>JAVA – handled the security aspect of the application using JWT authentication</li>
                                  <li>JAVA – developed the WLS module and microservices </li>
                                  <li>JAVA – used feign client and implemented Redis Cache</li>
                                  <li>Implemented LOG using logback.xml</li>
                                  <li>Implemented AOP (Aspect oriented programming)</li>
                                  <li>Created complex, nested, recursive DB queries for generating reports</li>
                                  <li>Created stored procedures </li>
                                  <li>Used JUnit to create unit test cases</li>
                                  <li>Created a set of different documents (architecture diagram, installation guides, port plan, ENV summary, Application setup document, Code guide etc.)</li>
                                </ul>
                                <h5>Integrations done</h5>
                                <ul className="project-info-list">
                                  <li>Google translation API and made the application multi-lingual</li>
                                </ul>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div className="timeline-item clearfix">
                  <div className="left-part">
                    <div className="sticky-block">
                      <h5 className="item-period">Apr – 2016 | March 2019</h5>
                      <h5 className="company-title">BitWise Ventures Pvt Ltd</h5>
                      <h5 className="company-location-title">A&apos;bad, Gujarat - India</h5>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="right-part">
                    <div className="experience-accordion-block">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="panel-title">
                              <div className="project-title-block">
                                Project: <span className="project-title">CrypKart</span>
                                <span className="pipe"></span>
                                <span className="position-title">Tech Lead</span>
                              </div>
                              <div className="project-stack-block">
                                <div className="project-info-tag-block">
                                  <span className="project-info-tag">Spring boot</span>
                                  <span className="project-info-tag">ThymeLeaf</span>
                                  <span className="project-info-tag">MySQL</span>
                                  <span className="project-info-tag">MQTT</span>
                                  <span className="project-info-tag">Bitcoin Blockchain</span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="text project-info-block">
                              <div className="project-info-card">
                                <h5>Project Brief</h5>
                                <p>
                                  The project was to map columns of a database table – in Oracle EBS. The utility duplicated certain tables and then provided an interface to be able to add and remove columns from the mapped duplicated tables. This was used to create dynamic views of the table and then work on them without affecting the original data tables in EBS. The tool could then generate XML based data files.
                                </p>
                                <h5>Responsibilities</h5>
                                <ul className="project-info-list">
                                  <li>Worked on ThymeLeaf </li>
                                  <li>Studied bitcoin blockchain and wallet integrations and implemented in the project</li>
                                  <li>Worked on security side of the application</li>
                                  <li>Entire frontend development was done by me using html, css, JS and bootstrap</li>
                                </ul>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <References />
        </div>
      </div>
    </section>
  </>
};

export default ExperienceInformationView;