import { createSlice } from "@reduxjs/toolkit";
import {
  performLogin,
  performGetTodo,
  performGetMe,
} from "../../thunk/auth.thunk";

const initialState = {
  loading: false,
  todo: null,
  token: null,
  step: null,
  error: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      Object.keys(action.payload).includes("token")
        // action.payload.token 
        && (state.token = (action.payload.token) ? action.payload.token.replace(/"/g, "") : null);
      Object.keys(action.payload).includes("step")
        // action.payload.step 
        && (state.step = action.payload.step);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performLogin.pending, (state) => {
        state.loading = true;
        state.token = null;
        state.error = null;
      })
      .addCase(performLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.data.jwt_token;
        state.step = action.payload.data.step;
        state.error = null;
      })
      .addCase(performLogin.rejected, (state, action) => {
        state.loading = false;
        state.token = null;
        state.error = action?.error.message;
      });

    //   new api call
    builder
      .addCase(performGetTodo.pending, (state) => {
        state.loading = true;
        state.todo = null;
      })
      .addCase(performGetTodo.fulfilled, (state, action) => {
        state.loading = false;
        state.todo = action.payload;
      })
      .addCase(performGetTodo.rejected, (state) => {
        state.loading = false;
        state.todo = null;
      });

    builder
      .addCase(performGetMe.pending, (state) => {
        state.loading = true;
        state.user = null;
      })
      .addCase(performGetMe.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.token = action.payload?.id;
      })
      .addCase(performGetMe.rejected, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null
      });
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
