import React from "react";
import { withUserLayout } from "../../../hoc";
import { useParams } from 'react-router';
import HomeView from "./view";

const HomePage = () => {

  const parmas = useParams();
  const stepName = parmas?.page;

  return <HomeView stepName={stepName} />;
};

export default withUserLayout(HomePage);
