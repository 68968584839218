import React, { useState } from "react";

const Skills = () => {
    const [selectedSkill, setSelectedSkill] = useState('allSkill')
    const handleSkills = (e) => {
        setSelectedSkill(e.target.value)
    }
    return <>
        <div className="col-md-6">
            <div className="section-title-block second-style">
                <div className="section-title d-flex align-items-center flex-wrap">
                    <h2 className="skill-head-title">Skills</h2>
                    <div className="skill-info-dropdown">
                        <select className="select-skill-dropdown" onClick={(e) => handleSkills(e)} >
                            <option value="allSkill">All</option>
                            <option value="database">Database</option>
                            <option value="frontend">FrontEnd</option>
                            <option value="backEnd">BackEnd</option>
                            <option value="devops">DevOps</option>
                            <option value="misc">Misc</option>
                        </select>
                    </div>
                    <div className="skill-info">
                        <ul>
                            <li><span className="tag-icon icon-green"><i className="ri-checkbox-blank-circle-fill"></i></span> Expert</li>
                            <li><span className="tag-icon icon-orange"><i className="ri-checkbox-blank-circle-fill"></i></span> Mediocre</li>
                            <li><span className="tag-icon icon-blue"><i className="ri-checkbox-blank-circle-fill"></i></span> Novice</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-content">
                <div className="skill-section">
                    {selectedSkill === 'allSkill' && (
                        <div className="skill-tag-list allSkill">
                            <h5 className="skill-title">All</h5>
                            <small className="green-color">MySQL</small>
                            <small className="green-color">Oracle 19c, XE, SE</small>
                            <small className="green-color">PostgreSQL</small>
                            <small className="green-color">MySQL</small>
                            <small className="green-color">Oracle 19c, XE, SE</small>
                            <small className="green-color">PostgreSQL</small>
                            <small className="green-color">Angular 7+</small>
                            <small className="green-color">Angular Material</small>
                            <small className="green-color">NodeJS</small>
                            <small className="green-color">NPM repository</small>
                            <small className="green-color">NPM library creation</small>
                            <small className="green-color">Angular Reactive Forms</small>
                            <small className="green-color">HTML5</small>
                            <small className="green-color">CSS3</small>
                            <small className="green-color">Flex</small>
                            <small className="green-color">BootStrap</small>
                            <small className="green-color">JavaScript</small>
                            <small className="green-color">Prototype JS</small>
                            <small className="orange-color">JAVA Spring</small>
                            <small className="orange-color">SpringBoot</small>
                            <small className="orange-color">Spring security</small>
                            <small className="orange-color">Microservices architecture</small>
                            <small className="orange-color">Thymeleaf</small>
                            <small className="orange-color">Hibernate</small>
                            <small className="orange-color">JPA</small>
                            <small className="orange-color">Eureka Proxy</small>
                            <small className="orange-color">Redis Cache</small>
                            <small className="orange-color">Feign Client</small>
                            <small className="orange-color">Rabbit MQ</small>
                            <small className="orange-color">Jenkins</small>
                            <small className="orange-color">CI/CD pipeline</small>
                            <small className="orange-color">Docker</small>
                            <small className="orange-color">Kubernetes</small>
                            <small className="orange-color">Bitbucket</small>
                            <small className="orange-color">GIT</small>
                            <small className="orange-color">Oracle Cloud</small>
                            <small className="orange-color">AWS</small>
                            <small className="blue-color">JIRA</small>
                            <small className="blue-color">ZOHO project manager</small>
                            <small className="blue-color">Linux Shell scripting</small>
                            <small className="blue-color">Performance tuning</small>
                            <small className="blue-color">Load balancing</small>
                            <small className="blue-color">Apache Proxy</small>
                            <small className="blue-color">Tomcat multi instances</small>
                        </div>
                    )}

                    {selectedSkill === 'database' && (
                        <div className="skill-tag-list">
                            <h5 className="skill-title">Database</h5>
                            <small className="green-color">MySQL</small>
                            <small className="green-color">Oracle 19c, XE, SE</small>
                            <small className="green-color">PostgreSQL</small>
                        </div>
                    )}

                    {selectedSkill === 'frontend' && (
                        <div className="skill-tag-list frontend">
                            <h5 className="skill-title">FrontEnd</h5>
                            <small className="green-color">Angular 7+</small>
                            <small className="green-color">Angular Material</small>
                            <small className="green-color">NodeJS</small>
                            <small className="green-color">NPM repository</small>
                            <small className="green-color">NPM library creation</small>
                            <small className="green-color">Angular Reactive Forms</small>
                            <small className="green-color">HTML5</small>
                            <small className="green-color">CSS3</small>
                            <small className="green-color">Flex</small>
                            <small className="green-color">BootStrap</small>
                            <small className="green-color">JavaScript</small>
                            <small className="green-color">Prototype JS</small>
                        </div>
                    )}
                    {selectedSkill === 'backEnd' && (
                        <div className="skill-tag-list">
                            <h5 className="skill-title">BackEnd</h5>
                            <small className="orange-color">JAVA Spring</small>
                            <small className="orange-color">SpringBoot</small>
                            <small className="orange-color">Spring security</small>
                            <small className="orange-color">Microservices architecture</small>
                            <small className="orange-color">Thymeleaf</small>
                            <small className="orange-color">Hibernate</small>
                            <small className="orange-color">JPA</small>
                            <small className="orange-color">Eureka Proxy</small>
                            <small className="orange-color">Redis Cache</small>
                            <small className="orange-color">Feign Client</small>
                            <small className="orange-color">Rabbit MQ</small>
                        </div>
                    )}
                    {selectedSkill === 'devops' && (
                        <div className="skill-tag-list ">
                            <h5 className="skill-title">DataDevOps</h5>
                            <small className="orange-color">Jenkins</small>
                            <small className="orange-color">CI/CD pipeline</small>
                            <small className="orange-color">Docker</small>
                            <small className="orange-color">Kubernetes</small>
                            <small className="orange-color">Bitbucket</small>
                            <small className="orange-color">GIT</small>
                            <small className="orange-color">Oracle Cloud</small>
                            <small className="orange-color">AWS</small>
                        </div>
                    )}
                    {selectedSkill === 'misc' && (
                        <div className="skill-tag-list ">
                            <h5 className="skill-title">Misc</h5>
                            <small className="blue-color">JIRA</small>
                            <small className="blue-color">ZOHO project manager</small>
                            <small className="blue-color">Linux Shell scripting</small>
                            <small className="blue-color">Performance tuning</small>
                            <small className="blue-color">Load balancing</small>
                            <small className="blue-color">Apache Proxy</small>
                            <small className="blue-color">Tomcat multi instances</small>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
}
export default Skills;
