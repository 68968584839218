/**
 * @author Prashant Chauhan
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import OnboardSkillsView from "./view";
import { PrivateRoutesURLs } from "../../../../../routes";
import { localStorageItems } from "../../../../../config";
import { API_STATUS, toastMessageType } from "../../../../../constants";
import { getLocalStorage, setLocalStorage, switchOnboardPath, toastMessage } from "../../../../../services";
import { performGetSkills, performRegistrationSkills, setToken, setOnboardRegistrationData } from "../../../../../redux";

const OnboardSkillsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { auth } = useSelector(authData => authData);
  const { token, step, ...authData } = auth;
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    dispatch(performGetSkills((res) => {
      setLocalStorage("skills", res)
    }))
  }, [])

  useEffect(() => {
    if (!token) toastMessage(toastMessageType.warning, "Please, sign in or register first.", "skill-warning");
    setTimeout(() => switchOnboardPath(step, token, authData, history, "onboardVerifyEmail"), 2000)
  }, [token, step]);

  const skills_options = () => getLocalStorage(localStorageItems.SKILL_DATA)?.map(skill => {
    return {
      value: skill.NAME,
      label: skill.NAME
    }
  });

  const onSubmit = () => {
    dispatch(performRegistrationSkills({
      skills: selectedSkills,
      cb: (response) => {
        if (response?.status === API_STATUS.SUCCESS) {
          dispatch(setOnboardRegistrationData({ selectedSkills }));
          dispatch(setToken({ ...auth, step: 3 }));
          toastMessage(toastMessageType.success, t("Step 3 complete"), "skill-success");
          setTimeout(() => history.push(PrivateRoutesURLs.educationDetails), 2000);
        }
      },
    }));
  };

  const onAddSkill = (skill) => {
    setSelectedSkills([skill, ...selectedSkills]);
  };

  const onRemoveSkill = (removeSkill) => setSelectedSkills(selectedSkills.filter((skill) => skill.skill_name !== removeSkill))

  return (
    <OnboardSkillsView
      t={t}
      onSubmit={onSubmit}
      onAddSkill={onAddSkill}
      selectedSkills={selectedSkills}
      skills_options={skills_options}
      onRemoveSkill={onRemoveSkill}
    />
  );
};

export default OnboardSkillsPage;
