import React, { useEffect } from "react";
import { performGetEducation } from "../../../../../redux";
import { useDispatch } from "react-redux";

const Education = () => {

    const dispatch = useDispatch();

    /**
     * @description call get api for education data
     */
    useEffect(() => {
        dispatch(performGetEducation());
    }, [])


    return <>

        <div className="col-md-6">
            <div className="section-title-block second-style">
                <h2 className="section-title">Education</h2>
            </div>
            <div className="section-content">
                <div className="timeline timeline-second-style clearfix">
                    <div className="timeline-item clearfix">
                        <div className="left-part">
                            <h5 className="item-period">2012</h5>
                            <span className="marks">6.4 CGPA</span>
                        </div>
                        <div className="divider"></div>
                        <div className="right-part">
                            <h4 className="item-title d-flex justify-content-between">Bachelor of Engineering </h4>
                            <div className="text">
                                <p>Gandhinagar Government Engineering College, sector-28, Gandhinagar.</p>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item clearfix">
                        <div className="left-part">
                            <h5 className="item-period">2008</h5>
                            <span className="marks">77%</span>
                        </div>
                        <div className="divider"></div>
                        <div className="right-part">
                            <h4 className="item-title d-flex justify-content-between">HSC </h4>
                            <div className="text">
                                <p>H.B.Kapadia high school, Shahibaug, Ahmedabad.</p>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item clearfix">
                        <div className="left-part">
                            <h5 className="item-period">2006</h5>
                            <span className="marks">85%</span>
                        </div>
                        <div className="divider"></div>
                        <div className="right-part">
                            <h4 className="item-title d-flex justify-content-between">SSC </h4>
                            <div className="text">
                                <p>H.B.Kapadia high school, Shahibaug, Ahmedabad.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Education;