import { API_ENDPOINTS, axiosInstance } from "../../config";

export const doLogin = (data) => {
  return axiosInstance.post(API_ENDPOINTS.login, data);
}

export const doGetTodo = () => {
  return axiosInstance.get(API_ENDPOINTS.todo);
};

export const doGetMe = () => {
  return axiosInstance.get(API_ENDPOINTS.todo);
};
