/**
 * @author Prashant Chauhan
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import SignInView from "./view";
import { switchOnboardPath, toastMessage } from "../../../services";
import { performLogin } from "../../../redux";
import { toastMessageType } from "../../../constants";

const password = "password";
const username = "username";
const email = "email";

const SignInPage = () => {
  const { token, step, ...auth } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputName, setInputName] = useState(username)

  useEffect(() => {
    if (token && step === 3) {
      toastMessage(toastMessageType.success, t("You are signed in"), "sign-in-success");
      setTimeout(() => switchOnboardPath(step, token, auth, history, "home"), 2000);
    }
    else if (token && step) {
      toastMessage(toastMessageType.success, t("You are signed in. Please, fill up remail step."), "sign-in-success-two");
      setTimeout(() => switchOnboardPath(step, token, auth, history, "home"), 2000);
    } else switchOnboardPath(step, token, auth, history, "home")
  }, [token, step]);

  /**
   * @param {Object} values object contains username and password
   * @description call post api with userdata
   */
  const handleSubmit = values => {
    dispatch(performLogin({
      "username": values.username,
      "password": values.password
    }));
  };

  /**
   * @param {String} value Input field value
   * @description Get input and decide it is username or email
   */
  const changeInputName = value => {
    let inputArray = value.split("");
    inputArray.includes("@") ? setInputName(email) : setInputName(username);
  }

  return <SignInView
    handleSubmit={handleSubmit}
    t={t}
    loading={auth?.loading}
    inputName={inputName}
    username={username}
    password={password}
    changeInputName={value => changeInputName(value)}
  />;
};

export default SignInPage;
