import { toast } from "react-toastify"

export const toastMessage = (type, message, customId = null) => {
    toast[type](message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: customId
    })
}