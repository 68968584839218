import axios from "axios";
import { toastMessageType } from "../constants";
import { toastMessage } from "../services/";
import { localStorageItems } from "./localStorage.config.js";

const axiosInstance = axios.create({
  // eslint-disable-next-line
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  timeout: 5000,
});

axiosInstance.interceptors.request.use(
  function (config) {
    if (localStorage.getItem(localStorageItems.jwt_token)) {
      config.headers["Authorization"] = `Bearer ${(localStorage.getItem(
        localStorageItems.jwt_token).replace(/"/g, "")
      )}`;
    }
    if (localStorage.getItem(localStorageItems.ACCEPT_LANGUAGE)) {
      config.headers["accept-language"] = (localStorage.getItem(localStorageItems.ACCEPT_LANGUAGE)).replace(/"/g, "")
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Do something with response error
    /**
     * @todo
     * Need to replace alert with UI component either snakebar
     */
    toastMessage(toastMessageType.error, error.response.data?.data ?? "Server is down. Sorry for inconvinience", "api_error");

    return Promise.reject(error);
  }
);

export default axiosInstance;
