export const API_STATUS = {
    SUCCESS: "SUCCESS",
};

export const API_ERRORS = {
    internal_server_error: "Internal server error",
    "confirm.user.request.invalid.otp": "Invalid OTP",
    "login.wrong.credentials": "Invalid username or password",
    "confirm.user.resend.otp": "OTP expired. Resend OTP"
};
