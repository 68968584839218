import React from "react";
import { Link } from "react-router-dom";

const References = () => {
    return <>
        <div className="col-md-4">
            <div className="section-title-block second-style">
                <h2 className="section-title">References</h2>
            </div>
            <div className="section-content">
                <div className="section-references">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-card sticky-block">
                                <h4>Sr. Director <span className="text-dark">@GlobalVox</span></h4>
                                <h3>Mr. Robert Long </h3>
                                <p><span>Phone:</span> <Link to="#">+91 12340 56780</Link></p>
                                <p><span>Email:</span> <Link to="#">robert@globalvox.com</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default References;