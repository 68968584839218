import React from "react";
import {UserHeaderComponent } from "../../components";
import "../../assets/css/style.min.css"

const withUserLayout = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <div id="page" className="page-layout">
          <UserHeaderComponent />
          <div id="main" className="site-main">
            <div className="pt-wrapper">
              <div className="subpages animated-subpages">
                <WrappedComponent />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return HOC;
};

export default withUserLayout;
