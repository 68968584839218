import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEducation } from "../../services";

export const performGetEducation = createAsyncThunk(
    "education/getData",
    async () => {
        const response = await getEducation();
        return response
    }
);
