import React from "react";
import Certifications from './certifications'
import Education from './education'
import Skills from './skills'

const EducationDetailsView = () => {

  return (<>
    <section data-id="education" data-title="Education" className="pt-page pt-page-resume pt-page-current">
      <div className="section-inner custom-page-content">
        <div className="row">
          <Education />
          <Skills />
          <Certifications />
        </div>
      </div>
    </section>
  </>)
};

export default EducationDetailsView;