/**
 * @author Prashant Chauhan
 */
import { localStorageItems } from "../../config"
import { OnboardRegistrationRoutesURLs, PrivateRoutesURLs } from "../../routes"
import { getLocalStorage } from "../index"
import { performGetCountry } from "../../redux"

/**
 * @param {String} path url path end-point
 * @param {any} history hook from component
 * @description redirect to given path
 */
export const goTo = (path, history) => {
    history.push(path)
}

/**
 * @description switch cases for route different page of onboarding stage
 * @param {Number} step step number of registration page if any
 * @param {String} token jwt_token || x-auth-token
 * @param {Object} auth rest state
 * @param {any} history Hook form component
 * @param {String} defaultRoute string of key of route of default page
 */
export const switchOnboardPath = (step, token, auth, history, defaultRoute) => {
    switch (step) {
        case 1:
            goTo(OnboardRegistrationRoutesURLs.onboardContactInfo, history);
            break;
        case 2:
            goTo(OnboardRegistrationRoutesURLs.onboardSkills, history);
            break;
        case 3:
            goTo(PrivateRoutesURLs.basicInformation, history);
            break;

        default:
            if (token)
                goTo(PrivateRoutesURLs.basicInformation, history);
            else {
                goTo(OnboardRegistrationRoutesURLs[defaultRoute], history);
            }
            break;
    }
}

/**
 * @description get country data from local storage if not available from api
 * @param {any} dispach hook from react component
 * @returns country data
 */
export const getCountry = (dispach) => {
    if (getLocalStorage(localStorageItems.COUNTRY_DATA)) return getLocalStorage("country-data")
    else dispach(
        performGetCountry({
            cb: (response) => {
                return response
            }
        })
    )
}