import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Formik } from "formik";
import Select from 'react-select';
import {
  OnboardContactInfoFormInitValue,
  OnboardContactInfoFormValidationSchema,
} from "./utils/form.utils";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { ErrorMessage } from "../../../../../components";

const OnboardContactInfoView = (props) => {

  const { t, onSubmit, country_options, state_options, setCountryFromName, getState, setStateFromName, country_code_option } = props;
  const { contactInfo } = useSelector(({ registration }) => registration);
  const [country, setCountry] = useState(null);
  const [stateCopy, setStateCopy] = useState(null);

  return (
    <div className="registerFormBlock">
      <h6>{t("contact-information")}</h6>
      <Formik
        initialValues={{ ...OnboardContactInfoFormInitValue, ...contactInfo }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
        validationSchema={OnboardContactInfoFormValidationSchema}
      >
        {({ handleChange, handleSubmit, isSubmitting, errors, handleBlur, setFieldValue, touched }) => {
          return (
            <form onSubmit={handleSubmit} id="contact-info-form">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("mobile")} Number</label>
                    <div className="mobileInputGroup">
                      <div className="mobileDialCode">
                        <Select
                          id="dialCode"
                          name="dialCode"
                          className="mobileDialCodeSelect"
                          placeholder="Dial"
                          styles={{
                            menu: () => ({
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              overflowY: "auto",
                              backgroundColor: "#fff",
                              position: "absolute",
                              zIndex: 10
                            }),
                            menuList: () => ({
                              width: 200,
                              height: "auto",
                              maxHeight: "240px",
                              overflowY: "auto"
                            })
                          }}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          onChange={selectedOption => handleChange({ target: { name: 'dialCode', value: (setCountryFromName(selectedOption.value)?.CALLING_CODE) ?? "" } })}
                          onBlur={() => handleBlur({ target: { name: 'dialCode' } })}
                          options={country_code_option()}
                        />
                        {touched.dialCode && <ErrorMessage id={"dialCode-error"} t={t} error={errors.dialCode} />}
                      </div>

                      <div className="mobileNumberCode">
                        <Field
                          id="mobilenumber"
                          name="mobilenumber"
                          type="number"
                          placeholder="Mobile Number"
                          className="form-control"
                        />
                        {touched.mobilenumber && <ErrorMessage id={"mobilenumber-error"} t={t} error={errors.mobilenumber} />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("Country of Residence")}</label>
                    <Select
                      id="country"
                      name="country"
                      onChange={selectedOption => {
                        if (setCountryFromName(selectedOption.value)?.ID !== country) {
                          setTimeout(() => {
                            setFieldValue("state", "");
                            setStateCopy({ value: "", label: "Select State..." })
                          }, 20)
                        }
                        handleChange({ target: { name: 'country', value: (setCountryFromName(selectedOption.value)?.ID) ?? "" } });
                        setCountry(setCountryFromName(selectedOption.value)?.ID);
                        getState(setCountryFromName(selectedOption.value)?.ID);
                      }}
                      onBlur={() => handleBlur({ target: { name: 'country' } })}
                      options={country_options()}
                    />
                    {touched.country && <ErrorMessage id={"country-error"} t={t} error={errors.country} />}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="label">{t("Address")}</label>
                    <Field
                      id="address"
                      type="text"
                      name="address"
                      className="form-control"
                      onBlur={() => handleBlur({ target: { name: 'address' } })}
                    />
                    {touched.address && <ErrorMessage id={"address-error"} t={t} error={errors.address} />}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("state")}</label>
                    <Select
                      id="state"
                      name="state"
                      value={stateCopy}
                      placeholder={country ? "Select State..." : "Select country first..."}
                      onChange={selectedOption => {
                        handleChange({ target: { name: 'state', value: setStateFromName(country, selectedOption.value)?.ID } });
                        setStateCopy(selectedOption)
                      }}
                      isDisabled={country ? false : true}
                      options={state_options(country ?? 101)}
                      onBlur={() => handleBlur({ target: { name: 'state' } })}
                    />
                    {touched.state && <ErrorMessage id={"state-error"} t={t} error={errors.state} />}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="label">{t("zipcode")}</label>
                    <Field
                      id="zipcode"
                      type="text"
                      name="zipcode"
                      className="form-control"
                    />
                    {touched.zipcode && <ErrorMessage id={"zipcode-error"} t={t} error={errors.zipcode} />}
                  </div>
                </div>
                <div className="col-12 mt-2 d-flex justify-content-end align-items-center">
                  <Button
                    id="submit"
                    type="submit"
                    variant="dark"
                    className="ml-2"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {t("save-and-next")}
                  </Button>
                </div>
              </div>
            </form >
          );
        }}
      </Formik >
    </div >
  );
};

OnboardContactInfoView.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  country_options: PropTypes.func.isRequired,
  defaultCountry: PropTypes.func.isRequired,
  state_options: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
  setCountryFromName: PropTypes.func.isRequired,
  setStateFromName: PropTypes.func.isRequired,
  country_code_option: PropTypes.func.isRequired
};

export default OnboardContactInfoView;
