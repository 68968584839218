import React from "react";
import PropTypes from "prop-types";
import OnboardVerifyEmailPage from "./steps/verify-email";
import OnboardContactInfoPage from "./steps/contact-info";
import OnboardSkillsPage from "./steps/skills";

const OnboardRegistrationView = ({ stepName }) => {
  const renderStep = () => {
    switch (stepName) {

      case "verify-email":
        return <OnboardVerifyEmailPage />;
      case "contact-info":
        return <OnboardContactInfoPage />;
      case "skills":
        return <OnboardSkillsPage />;

      default:
        break;
    }
  };

  return renderStep()

};

OnboardRegistrationView.propTypes = {
  stepName: PropTypes.string,
};

export default OnboardRegistrationView;
