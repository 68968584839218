import React from "react";

const BasicInformationView = () => {
  return <>
    <section data-id="objective" data-title="Objective" className="pt-page pt-page-about-me start-page second-style pt-page-current">
      <div className="section-inner start-page-full-width h-100">
        <div className="user-head-block">
          <div className="user-company-info">
            <div className="header-content">
              <div className="header-photo">
                <a><img src="/assets/img/gv-logo2.png" alt="GlobalVox" /></a>
              </div>
              <div className="header-titles">
                <a><h2>Mayank Chauhan</h2></a>
                <h6>FullStack Developer</h6>
              </div>
            </div>
          </div>
          <div className="user-objective-experience-block">
            <div className="section-content vcentered home-page-second-style">
              <div className="home-content">
                <div className="row flex-v-align align-items-center">
                  <div className="col-sm-12 col-md-5 col-lg-4">
                    <div className="home-photo">
                      <div className="hp-inner">
                        <img src="/assets/img/mayank-chauhan.png" alt="Mayank Chauhan" />
                      </div>
                    </div>
                    <div className="hp-dots"></div>
                  </div>
                  <div className="col-sm-12 col-md-7 col-lg-8">
                    <div className="home-text">
                      <h6>FullStack Developer</h6>
                      <h1>Mayank Chauhan</h1>
                      <p>
                        Seeking a job which provides opportunities and exposure for various professional challenges which helps to mould my career and where I can make effective use of my skills.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="home-contact-info">
                      <ul>
                        <li>
                          <h3>Email</h3>
                          <p>info@globalvoxinc.com</p>
                        </li>
                        <li>
                          <h3>Phone Number</h3>
                          <p><a>+91 98200 97200</a></p>
                          <p><a>+91 98200 97200</a></p>
                        </li>
                        <li>
                          <h3>Temporary Address</h3>
                          <p>801, Kataria Arced, Makarba Ahmedabad - 380051</p>
                        </li>
                        <li>
                          <h3>Permenent Address</h3>
                          <p>801, Kataria Arced, Makarba Ahmedabad - 380051</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default BasicInformationView;