import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { OnboardRegistrationRoutesURLs } from "../../../../../routes";
import { switchOnboardPath, getLocalStorage, toastMessage } from "../../../../../services";
import { localStorageItems } from "../../../../../config/localStorage.config";
import OnboardContactInfoView from "./view";
import { API_STATUS, toastMessageType } from "../../../../../constants";
import { performGetCountry, performGetState, performRegistrationContactInfo, setToken, setOnboardRegistrationData } from "../../../../../redux";

const OnboardContactInfoPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector(authData => authData)
  const { token, step, ...authData } = auth;
  const { t } = useTranslation();

  useEffect(() => {
    if (!getLocalStorage(localStorageItems.COUNTRY_DATA)) dispatch(performGetCountry())
    if (!getLocalStorage(`country_ID_101`)) dispatch(performGetState(101))
    state_options(101)
  }, []);

  useEffect(() => {
    if (!token) toastMessage(toastMessageType.warning, "Please, sign in or register first.", "contact-info-warning");
    setTimeout(() => switchOnboardPath(step, token, authData, history, "onboardVerifyEmail"), 2000)
  }, [token, step]);

  const onSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    dispatch(
      performRegistrationContactInfo({
        phonenumber: `+${values.dialCode}${values.mobilenumber}`,
        countryid: values.country,
        stateid: values.state,
        address: values.address,
        zipcode: values.zipcode,
        cb: (response) => {
          setSubmitting(false);
          if (response?.status === API_STATUS.SUCCESS) {
            dispatch(setOnboardRegistrationData({ contactIfo: { ...values } })); // Data have to be store in state
            dispatch(setToken({ ...auth, step: 2 }));
            toastMessage(toastMessageType.success, t("Step 2 complete"), "contact-info-success");
            setTimeout(() => history.push(OnboardRegistrationRoutesURLs.onboardSkills), 2000);
          } else console.log(response)
        },
      })
    );
  };

  const country_options = () => getLocalStorage(localStorageItems.COUNTRY_DATA)?.map(country => {
    return {
      value: country.COUNTRY_NAME,
      label:
        <div className="countrytitle">
          {country.COUNTRY_NAME}
          <img src={`/assets/flags/4x3/${(country.CODE_2).toLowerCase()}.svg`} alt="flag" className="flagIcon" />
        </div>
    }
  });

  const state_options = countryID => getLocalStorage(`country_ID_${countryID}`)?.map(state => {
    return {
      value: state.NAME,
      label: state.NAME
    }
  });

  const country_code_option = () => getLocalStorage(localStorageItems.COUNTRY_DATA)?.map(country => {
    return {
      value: country.COUNTRY_NAME,
      label:
        <div className="DiallistBlock">
          <img style={{ marginRight: 10 }} src={`/assets/flags/4x3/${(country.CODE_2).toLowerCase()}.svg`} alt="flag" className="flagIcon" />
          <div style={{ textAlign: "left", marginRight: 10 }}>{country.COUNTRY_NAME}</div>
          <div style={{ with: "auto", marginRight: 10 }}>+{country.CALLING_CODE}</div>
        </div>
    }
  });

  const defaultCountry = () => (getLocalStorage(localStorageItems.COUNTRY_DATA)?.find(
    country => {
      if (country.CODE_2 === "IN") {
        return country
      }
    }
  ))

  const setCountryFromName = country_name => {
    return getLocalStorage(localStorageItems.COUNTRY_DATA)?.find(
      country => {
        if (country.COUNTRY_NAME === country_name) {
          return country
        }
      }
    )
  }

  const getState = countrycode => dispatch(performGetState(countrycode));

  const setStateFromName = (countryID, stateName) => {
    return getLocalStorage(`country_ID_${countryID}`)?.find(
      state => {
        if (state.NAME === stateName) {
          return state.ID
        }
      }
    )
  }

  return <OnboardContactInfoView
    t={t}
    onSubmit={onSubmit}
    country_options={country_options}
    state_options={state_options}
    country_code_option={country_code_option}
    defaultCountry={defaultCountry}
    getState={getState}
    setCountryFromName={setCountryFromName}
    setStateFromName={setStateFromName}
  />;
};

export default OnboardContactInfoPage;
