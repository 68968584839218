const PrivateRoutesURLs = {
  user: "/user/",
  basicInformation: "/user/basic-information",
  contactInformation: "/user/contact-information",
  educationDetails: "/user/education-details",
  projectInformation: "/user/project-information",
  publicationsPatents: "/user/publications-patents",
  skills: "/user/skills",
  socialMediaLinks: "/user/social-media-links",
  trainingCertification: "/user/training-certification",
  travelVisa: "/user/travel-visa",
  bioInformation: "/user/bioInformation",
  experienceInformation: "/user/experienceInformation"
};

export default PrivateRoutesURLs;
