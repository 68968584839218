import * as Yup from "yup";
import { ERROR_STATMENTS } from "../../../../../../constants";

export const OnboardContactInfoFormInitValue = {
  mobile: "",
  address: "",
  country: "",
  state: "",
  zipcode: "",
  dialCode: "",
  mobilenumber: "",
};

export const OnboardContactInfoFormValidationSchema = Yup.object().shape({

  address: Yup.string().required(ERROR_STATMENTS.INPUT_REQUIRE),
  country: Yup.string().required(ERROR_STATMENTS.INPUT_REQUIRE),
  state: Yup.string().required(ERROR_STATMENTS.INPUT_REQUIRE),
  zipcode: Yup.string()
    .required(ERROR_STATMENTS.INPUT_REQUIRE)
    .matches(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/, ERROR_STATMENTS.ENTER_VALID_INPUT),

  dialCode: Yup.string().required(ERROR_STATMENTS.INPUT_REQUIRE),
  mobilenumber: Yup.string()
    .required(ERROR_STATMENTS.INPUT_REQUIRE)
    .max(15, ERROR_STATMENTS.MAX_LIMIT_15_CHARACTER)
    .min(6, ERROR_STATMENTS.MIN_SIX_CHARACTER_REQUIRE)
});
