import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGetMe, doGetTodo, doLogin } from "../../services";

export const performLogin = createAsyncThunk("auth/login", async (data) => {
  const response = await doLogin(data);
  return response;
});

export const performGetTodo = createAsyncThunk("test/todo", async () => {
  const response = await doGetTodo();
  return response;
});

export const performGetMe = createAsyncThunk("auth/me", async () => {
  const response = await doGetMe();
  return response;
});
