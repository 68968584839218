import { createAsyncThunk } from "@reduxjs/toolkit";
import { localStorageItems } from "../../config";
import {
  setLocalStorage,
  doGetCountry,
  doGetSkills,
  doGetState,
  doRegistration,
  doRegistrationContactInfo,
  doRegistrationSkills,
  doResendOTP,
  doVerify,
} from "../../services";

export const performRegistration = createAsyncThunk(
  "onboard/register",
  async (data) => {
    try {
      const response = await doRegistration(data);
      data?.cb(response);
      return response;
    } catch (error) {
      data?.cb(error);
      return error;
    }
  }
);
export const performRegistrationContactInfo = createAsyncThunk(
  "onboard/contact-info",
  async (data) => {
    const response = await doRegistrationContactInfo(data);
    data?.cb(response);
    return response;
  }
);
export const performRegistrationSkills = createAsyncThunk(
  "onboard/skills",
  async (data) => {
    const response = await doRegistrationSkills(data);
    data?.cb(response);
    return response;
  }
);

export const performVerifyUser = createAsyncThunk(
  "onboard/verify",
  async (data) => {
    const response = await doVerify(data);
    data?.cb(response);
    return response;
  }
);

export const performResendOTP = createAsyncThunk(
  "onboard/resend-otp",
  async (data) => {
    const response = await doResendOTP(data);
    data?.cb(response);
    return response;
  }
);

export const performGetCountry = createAsyncThunk(
  "country",
  async (data) => {
    const response = await doGetCountry();
    setLocalStorage(localStorageItems.COUNTRY_DATA, response.data)
    data.cb(response);
    return response;
  }
)

export const performGetState = createAsyncThunk(
  "state",
  async (data) => {
    const response = await doGetState(data);
    setLocalStorage(`country_ID_${data}`, response.data)
    data.cb(response);
    return response;
  }
)

export const performGetSkills = createAsyncThunk(
  "state",
  async (data) => {
    const response = await doGetSkills();
    setLocalStorage(`skills`, response.data)
    data.cb(response);
    return response;
  }
)
