export const UserTypes = {
  IT_PROFESSIONAL: "IT_PROFESSIONAL",
};

export const ONBOARD_CONSTANT = {
  CONTACT_INFORMATION: "contact-information",
  RESEND_OTP: "resend-otp",
  FORGOT_PASSWORD: "forgot-password",
  SUBMIT: "submit"
}
