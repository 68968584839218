import React from 'react'
import { PlaceAutocomplete } from '../../../components'

const ForgotPassword = () => {
    return (
        <div>
            Forgot password
            <PlaceAutocomplete />
        </div>
    )
}

export default ForgotPassword
