import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import OnboardVerifyEmailView from "./view";
import { API_STATUS, toastMessageType, UserTypes } from "../../../../../constants";
import { OnboardRegistrationRoutesURLs } from "../../../../../routes";
import { switchOnboardPath, toastMessage } from "../../../../../services";
import { performRegistration, performResendOTP, performVerifyUser, setOnboardRegistrationData, setToken } from "../../../../../redux";

const OnboardVerifyEmailPage = () => {
  const history = useHistory();
  const { auth } = useSelector(authData => authData)
  const { token, step, ...authData } = auth;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [verifyOtp, setVerifyOtp] = useState(false);

  useEffect(() => {
    switchOnboardPath(step, token, authData, history, "onboardVerifyEmail")
  }, [token, step]);

  const onSubmit = (values, setSubmitting) => {
    dispatch(
      performRegistration({
        username: values.username,
        email_address: values.email,
        first_name: values.firstname,
        last_name: values.lastname,
        user_type: UserTypes.IT_PROFESSIONAL,
        cb: (response) => {
          setSubmitting(false);
          if (response?.status === API_STATUS.SUCCESS) {
            dispatch(setOnboardRegistrationData({ userData: { ...values } }));
            toastMessage(toastMessageType.success, t("otp_sent"), "contact-info-otp");
            setVerifyOtp(true);
          }
        },
      })
    );
  };

  const onVerifyOtp = (values, setSubmitting) => {
    dispatch(
      performVerifyUser({
        email_address: values.email,
        otp: values.otp,
        cb: (response) => {
          setSubmitting(false);
          if (response?.status === API_STATUS.SUCCESS) {
            setVerifyOtp(false);
            dispatch(setToken({ ...auth, step: 1, token: response?.data.jwt_token }))
            toastMessage(toastMessageType.success, t("register successful and credentials shared to your email."), "contact-info-success");
            setTimeout(() => history.push(OnboardRegistrationRoutesURLs.onboardContactInfo), 2000);
          }
        },
      })
    );
  };

  const editInfo = () => {
    setVerifyOtp(false)
  }

  const onResendOtp = (values) => {
    dispatch(
      performResendOTP({
        email_address: values.email,
        cb: (response) => {
          if (response?.status === API_STATUS.SUCCESS) {
            toastMessage(toastMessageType.success, t("otp_sent"), "contact-info-otp");
          }
        },
      })
    );
  };

  return (
    <OnboardVerifyEmailView
      t={t}
      onSubmit={onSubmit}
      verifyOtp={verifyOtp}
      onVerifyOtp={onVerifyOtp}
      onResendOtp={onResendOtp}
      editInfo={editInfo}
    />
  );
};

export default OnboardVerifyEmailPage;
