import { createSlice } from "@reduxjs/toolkit";
import { performGetSkills } from "../index";

const initialState = {
    loading: false,
    error: null,
    skills: null
}

export const skillsSlice = createSlice({
    name: "skills",
    initialState,
    reducers: {
        setSkills: (state, action) => {
            state = {
                ...state,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(performGetSkills.fulfilled, (state) => {
                state.loading = false;
                state.error = null
            })
            .addCase(performGetSkills.pending, (state) => {
                state.loading = true
            })
            .addCase(performGetSkills.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error
            })
    }
})

export const { setSkills } = skillsSlice.actions;

export default skillsSlice.reducer;