import React from "react";
import { Link } from "react-router-dom";

const BioInformationView = () => {
  return <>
    <section data-id="bio" data-title="Bio" className="pt-page pt-page-extra pt-page-current">
      <div className="section-inner custom-page-content">
        <div className="section-title-block second-style">
          <h2 className="section-title">Bio</h2>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-lg-5 col-xl-4">
              <div className="section-bio-left1">
                <div className="bio-content-block">
                  <div className="bio-video-block">
                    <div className="video-block">
                      <div className="embed-responsive embed-responsive-21by9">
                        {/* <!-- <iframe width="100%" height="500" src="https://www.youtube.com/embed/ecw_ugVd81k" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> --> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bio-content-block">
                  <h3>Languages</h3>
                  <div className="bio-info-block">
                    <table className="languages-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Read</th>
                          <th>Write</th>
                          <th>Speak</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="language-name">English</span></td>
                          <td>Fluent</td>
                          <td>Fluent</td>
                          <td>Fluent</td>
                        </tr>
                        <tr>
                          <td><span className="language-name">Hindi</span></td>
                          <td>Fluent</td>
                          <td>Manageable</td>
                          <td>Novice</td>
                        </tr>
                        <tr>
                          <td><span className="language-name">Gujarati</span></td>
                          <td>Fluent</td>
                          <td>Manageable</td>
                          <td>Fluent</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="bio-content-block">
                  <h3>Hobbies</h3>
                  <div className="hobbies-tag-list">
                    <small className="badge badge-darkblue">Reading</small>
                    <small className="badge badge-darkblue">Music</small>
                    <small className="badge badge-darkblue">Travel</small>
                    <small className="badge badge-darkblue">Game</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xl-8">
              <div className="section-bio">
                <div className="row">
                  <div className="col-md-6">
                    <div className="bio-content-block">
                      <h3>Strenths</h3>
                      <div className="bio-info-block">
                        <ul className="bio-info-list">
                          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident consectetur veniam expedita error impedit reprehenderit.</li>
                          <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum, ipsa?</li>
                          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bio-content-block">
                      <h3>Weaknesses</h3>
                      <div className="bio-info-block">
                        <ul className="bio-info-list">
                          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident consectetur veniam expedita error impedit reprehenderit.</li>
                          <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum, ipsa?</li>
                          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bio-content-block">
                  <h3>5 Year Plan</h3>
                  <div className="bio-info-block">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas qui odit rem optio? Aspernatur, accusamus.Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas qui odit rem optio? Aspernatur, accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas qui odit rem optio? Aspernatur, accusamus.</p>
                  </div>
                </div>
                <div className="bio-content-block">
                  <h3>Contact</h3>
                  <div className="bio-info-block">
                    <div className="section-contact">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <div className="box-card">
                            <h3>Email </h3>
                            <p><Link to="#">robert@globalvox.com</Link></p>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <div className="box-card">
                            <h3>Phone </h3>
                            <p><Link to="#">+91 12340 56780</Link></p>
                            <p><Link to="#">+91 12340 56780</Link></p>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <div className="box-card">
                            <h3>Temporary Address</h3>
                            <p>801, Kataria Arced, Makarba Ahmedabad - 380051</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <div className="box-card">
                            <h3>Permenent Address</h3>
                            <p>801, Kataria Arced, Makarba Ahmedabad - 380051</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
};

export default BioInformationView;