//All value of statments are key of language file
export const ERROR_STATMENTS = {
    INPUT_REQUIRE: "input-required",
    VALID_NUMBER: "alert-valid-number",
    ENTER_VALID_INPUT: "alert-valid-input",
    MIN_SIX_CHARACTER_REQUIRE: "alert-min-six-character-required",
    MIN_EIGHT_CHARACTER_REQUIRE: "alert-min-eight-character-required",
    MAX_LIMIT_FIFTEEN: "alert-max-limit-fifteen-character",
    CAPITAL_LETTER_REQUIRE: "alert-capital-letter-required",
    FORGOT_PASSWORD: "forgot-password",
    Required: "required",
    ALPHA_NUMERIC_REQUIRED: "alert-alpha-numeric-required",
    MAX_LIMIT_160_CHARACTER: "alert-max-limit-160-character",
    MAX_LIMIT_40_CHARACTER: "alert-max-limit-40-character",
    MAX_LIMIT_20_CHARACTER: "alert-max-limit-20-character",
    MAX_LIMIT_15_CHARACTER: "alert-max-limit-15-character",
    ENTER_VALID_EMAIL: "alert-enter-valid-email",
    CAPTCHA_VARIFICATION_REQUIRED: "alert-captcha-varification-required",
    NUMBER_REQUIRED: "alert-number-required",
    SPACE_SPECIAL_CHARACTER_NOT_ALLOWED: "alert-space-character",
    SPECIAL_CHARACTER_REQUIRED: "alert-special-character",
    AT_NOT_ALLOWED: "alert-at-not-allowed"
};