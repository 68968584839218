import { createSlice } from "@reduxjs/toolkit";
import {
  performRegistration,
  performRegistrationContactInfo,
  performRegistrationSkills,
  performResendOTP,
  performVerifyUser,
} from "../../index";

const initialState = {
  loading: false,
  error: null,
  onboardData: {}
};

export const registrationSlice = createSlice({
  name: "regisration",
  initialState,
  reducers: {
    setOnboardRegistrationData: (state, action) => {
      state.onboardData = {
        ...state.onboardData,
        ...action.payload
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performRegistration.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(performRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error;
      });

    builder
      .addCase(performRegistrationContactInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performRegistrationContactInfo.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(performRegistrationContactInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error;
      });

    builder
      .addCase(performRegistrationSkills.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performRegistrationSkills.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(performRegistrationSkills.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error;
      });

    builder
      .addCase(performVerifyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performVerifyUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(performVerifyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error;
      });

    builder
      .addCase(performResendOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performResendOTP.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(performResendOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error;
      });
  },
});

export const { setOnboardRegistrationData } = registrationSlice.actions;

export default registrationSlice.reducer;
