import React from "react";
import PropTypes from "prop-types";
import {
  BasicInformationPage,
  ContactInformationPage,
  EducationDetailsPage,
  ProjectInformationPage,
  PublicationsAndPatentsPage,
  SkillsPage,
  SocialMediaLinksPage,
  TrainingAndCertificationPage,
  TravelAndVisaPage,
  BioInformation,
  ExperienceInformation
} from '../..';

const HomeView = ({ stepName }) => {

  const renderStep = () => {
    switch (stepName) {
      case "basic-information":
        return <BasicInformationPage />;
      case "contact-information":
        return <ContactInformationPage />;
      case "education-details":
        return <EducationDetailsPage />;
      case "project-information":
        return <ProjectInformationPage />;
      case "publications-patents":
        return <PublicationsAndPatentsPage />;
      case "skills":
        return <SkillsPage />;
      case "social-media-links":
        return <SocialMediaLinksPage />;
      case "training-certification":
        return <TrainingAndCertificationPage />;
      case "travel-visa":
        return <TravelAndVisaPage />;
      case "bioInformation":
        return <BioInformation />;
      case "experienceInformation":
        return <ExperienceInformation />;
      default:
        return <BasicInformationPage />;
    }
  };
  return (renderStep());
};

/**
 * @todo
 * set types as per requirement
 */
HomeView.propTypes = {
  stepName: PropTypes.any
};

export default HomeView;
