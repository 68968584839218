import { createSlice } from "@reduxjs/toolkit";
import { performGetEducation } from "../../index";

const initialState = {
    loading: false,
    error: null,
    educationData: {},
};

export const educationSlice = createSlice({
    name: "education",
    initialState,
    reducers: {
        setEducationData: (state, action) => {
            state.educationData = {
                ...state.educationData,
                ...action.payload
            }
        },
    },
    extraReducers: builder => {
        builder
            .addCase(performGetEducation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(performGetEducation.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(performGetEducation.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error;
            });
    },
});

export const { setEducationData } = educationSlice.actions;

export default educationSlice.reducer;
