import React from "react";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const UserHeaderComponent = () => {
	// const { t, i18n } = useTranslation();

	//   based on redux state header can be set here
	return (
		<>
			<header id="site_header" className="header mobile-menu-hide">
				<div className="header-content clearfix">
					<div className="header-image">
						<Link className="menu-user-image">
							<img src="/assets/img/mayank-chauhan.png" alt="Mayank Chauhan" />
						</Link>
					</div>

					<div className="site-title-block">
						<Link>
							<h1 className="site-title">Mayank Chauhan</h1>
						</Link>
					</div>

					<div className="site-nav dl-menuwrapper">
						<ul id="nav" className="site-main-menu site-auto-menu">
							<li className="active"><Link to="/user/basic-information" className="pt-trigger">Objective</Link></li>
							<li><Link to="/user/experienceInformation" className="pt-trigger">Experience</Link></li>
							<li><Link to="/user/education-details" className="pt-trigger">Education</Link></li>
							<li><Link to="/user/social-media-links" className="pt-trigger">Links</Link></li>
							<li><Link to="/user/travel-visa" className="pt-trigger">Travel and Visa</Link></li>
							<li><Link to="/user/bioInformation" className="pt-trigger">Bio</Link></li>
						</ul>
						<div className="download-btn-block">
							<Link className="btn btn-primary" to="">Download CV</Link>
						</div>
					</div>

				</div>
			</header>

			<div className="mobile-header mobile-visible">
				<div className="mobile-logo-container">
					<div className="mobile-header-image">
						<Link>
							<img src="images/mayank-chauhan.png" alt="Mayank Chauhan" />
						</Link>
					</div>

					<div className="mobile-site-title">
						<Link>Mayank Chauhan </Link>
						<h6>FullStack Developer</h6>
					</div>
				</div>

				<Link className="menu-toggle mobile-visible">
					<i className="ri-menu-2-line"></i>
				</Link>
			</div>
		</>
	);
};

export default UserHeaderComponent;
