import React from 'react'
import PropTypes from "prop-types";
import { OnboardRegistrationRoutesURLs } from '../../../routes';
import { OnboardRegistrationPage, SignInPage } from '../..';
import ForgotPassword from '../forgot-password';

const OnBoardView = ({ t, stepName, history }) => {

  let check = stepName ? false : true

  const renderStep = () => {

    switch (stepName) {
      case "verify-email":
      case "contact-info":
      case "skills":
        return <OnboardRegistrationPage />;
      case "forgot-password":
        return <ForgotPassword />;

      default:
        return <SignInPage />;
    }
  };


  return (
    <div className="oopsWrapper">
      <div className="oopsFormContainer">
        <div className="registerFormWrap">
          {((!stepName) || (stepName === "verify-email")) &&
            (<div className="radio-toggles">
              <input type="radio" id="signIn" name="toggle" onClick={() => history.push(OnboardRegistrationRoutesURLs.home)} readOnly={true} checked={check} />
              <label htmlFor="signIn">{t("sign-in")}</label>
              <input type="radio" id="register" name="toggle" onClick={() => history.push(OnboardRegistrationRoutesURLs.onboardVerifyEmail)} readOnly={true} checked={!check} />
              <label htmlFor="register">{t("register")}</label>
              <div className="slide-item"></div>
            </div>)
          }
          {renderStep()}
        </div>
      </div>
    </div>
  );
};

/**
 * @todo
 * set types as per requirement
 */
OnBoardView.propTypes = {
  t: PropTypes.any,
  stepName: PropTypes.any,
  history: PropTypes.any
};

export default OnBoardView;
