import React from "react";
import { useParams } from "react-router";
import OnboardRegistrationView from "./view.jsx";

const OnboardRegistrationPage = () => {
  const parmas = useParams();
  let stepName = parmas?.type;

  return <OnboardRegistrationView stepName={stepName} />;
};

export default OnboardRegistrationPage;
