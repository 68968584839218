import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth/auth.slice";
import registrationReducer from "../slices/onboard/registration.slice";
import locationReducer from "../slices/location.slice";
import educationReducer from "../slices/education/education.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    registration: registrationReducer,
    location: locationReducer,
    education: educationReducer
  },
});
